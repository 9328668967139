import { Component, Input, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.services';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM',
  },
  display: {
    dateInput: 'YYYY-MM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-dsr-report',
  templateUrl: './dsr-report.component.html',
  styleUrls: ['./dsr-report.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DsrReportComponent implements OnInit{
  date = new FormControl(moment());
  product_code:any=''
  product:any=[];
  @Input() rocode:any;
  monthData:any =   {}  
  data:any=[];
  producthash:any={}
// data = [
//   {
//     "day": 1,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 0,
//         "start_product_volume": 8319.2,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 8319.2,
//         "total_sales": 0
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 0,
//         "start_product_volume": 9620.4,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 9620.4,
//         "total_sales": 0
//       }
//     ],
//     "total_tank_sales": 0,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 889340,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 1432.9499926567078,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 481641.28,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 712.4599997997284,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 394969.28,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 93.44999992847443,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 177393.56,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 48.58000063896179,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 1150039.87,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 1368.079995840788,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 609534.38,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 711.0999985039234,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 0,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 4366.619987368584,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 2,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 5784.4,
//         "start_product_volume": 6166,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 6166,
//         "total_sales": 381.60000000000036
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 5784.4,
//         "start_product_volume": 7254.4,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 7254.4,
//         "total_sales": 1470
//       }
//     ],
//     "total_tank_sales": 5866,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 891920.44,
//         "start_totalizer_volume": 890779.69,
//         "product_code": "MS",
//         "total_totalizer": 1140.75,
//         "total_sales": 1127.2499980926514,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 482745.16,
//         "start_totalizer_volume": 482380.75,
//         "product_code": "MS",
//         "total_totalizer": 364.4099999999744,
//         "total_sales": 380.09000357985497,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 395062.72,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 395062.72,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 177442.14,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 177442.14,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 1152397.37,
//         "start_totalizer_volume": 1151407.87,
//         "product_code": "MS",
//         "total_totalizer": 989.5,
//         "total_sales": 992.1299988627434,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 610704.19,
//         "start_totalizer_volume": 610245.44,
//         "product_code": "MS",
//         "total_totalizer": 458.75,
//         "total_sales": 465.4999976158142,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 2123681.6,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 2964.969998151064,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 3,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 2580.1,
//         "start_product_volume": 4703,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 4703,
//         "total_sales": 2122.9
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 2580.1,
//         "start_product_volume": 5784.4,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 5784.4,
//         "total_sales": 3204.2999999999997
//       }
//     ],
//     "total_tank_sales": 9830.599999999999,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 892783.69,
//         "start_totalizer_volume": 891920.44,
//         "product_code": "MS",
//         "total_totalizer": 863.25,
//         "total_sales": 835.199998497963,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 483156.56,
//         "start_totalizer_volume": 482745.16,
//         "product_code": "MS",
//         "total_totalizer": 411.4000000000233,
//         "total_sales": 416.78999853134155,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 396368.25,
//         "start_totalizer_volume": 395062.72,
//         "product_code": "MS",
//         "total_totalizer": 1305.530000000028,
//         "total_sales": 1325.7699918150902,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 177948.92,
//         "start_totalizer_volume": 177442.14,
//         "product_code": "MS",
//         "total_totalizer": 506.77999999999884,
//         "total_sales": 528.3599992394447,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 1153393.5,
//         "start_totalizer_volume": 1152397.37,
//         "product_code": "MS",
//         "total_totalizer": 996.1299999998882,
//         "total_sales": 975.9499959945679,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 611391.88,
//         "start_totalizer_volume": 610704.19,
//         "product_code": "MS",
//         "total_totalizer": 687.6900000000605,
//         "total_sales": 687.6800000667572,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 16658.91000000006,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 4769.7499841451645,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 4,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 0,
//         "start_product_volume": 2992,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 2992,
//         "total_sales": 2992
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 0,
//         "start_product_volume": 2580.1,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 2580.1,
//         "total_sales": 2580.1
//       }
//     ],
//     "total_tank_sales": -1101.8000000000002,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 893851.13,
//         "start_totalizer_volume": 892783.69,
//         "product_code": "MS",
//         "total_totalizer": 1067.4400000000605,
//         "total_sales": 1067.4399959445,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 483631.78,
//         "start_totalizer_volume": 483156.56,
//         "product_code": "MS",
//         "total_totalizer": 475.22000000003027,
//         "total_sales": 475.22000336647034,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 396822.45,
//         "start_totalizer_volume": 396368.25,
//         "product_code": "MS",
//         "total_totalizer": 454.20000000001164,
//         "total_sales": 516.4499953389168,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 178154.06,
//         "start_totalizer_volume": 177948.92,
//         "product_code": "MS",
//         "total_totalizer": 205.13999999998487,
//         "total_sales": 192.7100032567978,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 1154713.75,
//         "start_totalizer_volume": 1153393.5,
//         "product_code": "MS",
//         "total_totalizer": 1320.25,
//         "total_sales": 1334.990004658699,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 611926.62,
//         "start_totalizer_volume": 611391.88,
//         "product_code": "MS",
//         "total_totalizer": 534.7399999999907,
//         "total_sales": 533.3899962306023,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 14388.200000000506,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 4120.199998795986,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 5,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 7113.9,
//         "start_product_volume": 6123,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 6123,
//         "total_sales": -990.8999999999996
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 7113.9,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": -7113.9
//       }
//     ],
//     "total_tank_sales": -5655.799999999999,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 895188.81,
//         "start_totalizer_volume": 893851.13,
//         "product_code": "MS",
//         "total_totalizer": 1337.6800000000512,
//         "total_sales": 1246.5699974298477,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 484329.59,
//         "start_totalizer_volume": 483631.78,
//         "product_code": "MS",
//         "total_totalizer": 697.8099999999977,
//         "total_sales": 682.9800007343292,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 397119.97,
//         "start_totalizer_volume": 396822.45,
//         "product_code": "MS",
//         "total_totalizer": 297.5199999999604,
//         "total_sales": 295.52999782562256,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 178345.36,
//         "start_totalizer_volume": 178154.06,
//         "product_code": "MS",
//         "total_totalizer": 191.29999999998836,
//         "total_sales": 164.51000022888184,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 1156697.5,
//         "start_totalizer_volume": 1154713.75,
//         "product_code": "MS",
//         "total_totalizer": 1983.75,
//         "total_sales": 1962.1600042581558,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 612752,
//         "start_totalizer_volume": 611926.62,
//         "product_code": "MS",
//         "total_totalizer": 825.3800000000047,
//         "total_sales": 784.8799983859062,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 18071.990000000107,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 5136.629998862743,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 6,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 5595.2,
//         "start_product_volume": 1837,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 1837,
//         "total_sales": -3758.2
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 5595.2,
//         "start_product_volume": 7113.9,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 7113.9,
//         "total_sales": 1518.6999999999998
//       }
//     ],
//     "total_tank_sales": 4549.4,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 896332.94,
//         "start_totalizer_volume": 895188.81,
//         "product_code": "MS",
//         "total_totalizer": 1144.1299999998882,
//         "total_sales": 1111.8599979281425,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 484954.75,
//         "start_totalizer_volume": 484329.59,
//         "product_code": "MS",
//         "total_totalizer": 625.1599999999744,
//         "total_sales": 625.1499971747398,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 397792,
//         "start_totalizer_volume": 397119.97,
//         "product_code": "MS",
//         "total_totalizer": 672.0300000000279,
//         "total_sales": 672.0200011730194,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 178435.09,
//         "start_totalizer_volume": 178345.36,
//         "product_code": "MS",
//         "total_totalizer": 89.73000000001048,
//         "total_sales": 89.7400004863739,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 1158032,
//         "start_totalizer_volume": 1156697.5,
//         "product_code": "MS",
//         "total_totalizer": 1334.5,
//         "total_sales": 1334.5199975669384,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 613614.63,
//         "start_totalizer_volume": 612752,
//         "product_code": "MS",
//         "total_totalizer": 862.6300000000047,
//         "total_sales": 854.8500001430511,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 16479.51999999935,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 4688.139994472265,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 7,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 9100.8,
//         "start_product_volume": 1081,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 1081,
//         "total_sales": -8019.799999999999
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 9100.8,
//         "start_product_volume": 5595.2,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 5595.2,
//         "total_sales": -3505.5999999999995
//       }
//     ],
//     "total_tank_sales": -20593.999999999996,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 647715.56,
//         "start_totalizer_volume": 896332.94,
//         "product_code": "MS",
//         "total_totalizer": -248617.3799999999,
//         "total_sales": 1416.799997806549,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 1758368.37,
//         "start_totalizer_volume": 484954.75,
//         "product_code": "MS",
//         "total_totalizer": 1273413.62,
//         "total_sales": 648.3700022697449,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 399205.94,
//         "start_totalizer_volume": 397792,
//         "product_code": "MS",
//         "total_totalizer": 1413.9400000000023,
//         "total_sales": 1400.4499925673008,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 1219211.25,
//         "start_totalizer_volume": 178435.09,
//         "product_code": "MS",
//         "total_totalizer": 1040776.16,
//         "total_sales": 527.319997549057,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 1158577.75,
//         "start_totalizer_volume": 1158032,
//         "product_code": "MS",
//         "total_totalizer": 545.75,
//         "total_sales": 537.6700012683868,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 613865.94,
//         "start_totalizer_volume": 613614.63,
//         "product_code": "MS",
//         "total_totalizer": 251.30999999993946,
//         "total_sales": 251.3200016617775,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 8004690.870000002,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 4781.929993122816,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 8,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 6555.1,
//         "start_product_volume": 7872.4,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 7872.4,
//         "total_sales": 1317.2999999999993
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 6555.1,
//         "start_product_volume": 9100.8,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 9100.8,
//         "total_sales": 2545.699999999999
//       }
//     ],
//     "total_tank_sales": 9397.399999999998,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 899145.44,
//         "start_totalizer_volume": 647715.56,
//         "product_code": "MS",
//         "total_totalizer": 251429.8799999999,
//         "total_sales": 1371.3699970245361,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 486388.97,
//         "start_totalizer_volume": 1758368.37,
//         "product_code": "MS",
//         "total_totalizer": -1271979.4000000001,
//         "total_sales": 789.9099995493889,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 399205.94,
//         "product_code": "MS",
//         "total_totalizer": -399205.94,
//         "total_sales": 288.16000032424927,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 1219211.25,
//         "product_code": "MS",
//         "total_totalizer": -1219211.25,
//         "total_sales": 63.43000090122223,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 1159757.25,
//         "start_totalizer_volume": 1158577.75,
//         "product_code": "MS",
//         "total_totalizer": 1179.5,
//         "total_sales": 1193.01999771595,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 614781.06,
//         "start_totalizer_volume": 613865.94,
//         "product_code": "MS",
//         "total_totalizer": 915.1200000001118,
//         "total_sales": 909.7199994921684,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": -10102501.11,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 4615.609995007515,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 9,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 4832.8,
//         "start_product_volume": 5719.4,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 5719.4,
//         "total_sales": 886.5999999999995
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 4832.8,
//         "start_product_volume": 6555.1,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 6555.1,
//         "total_sales": 1722.3000000000002
//       }
//     ],
//     "total_tank_sales": 7083.2,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 899572.25,
//         "start_totalizer_volume": 899145.44,
//         "product_code": "MS",
//         "total_totalizer": 426.8100000000559,
//         "total_sales": 426.78999948501587,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 486646.87,
//         "start_totalizer_volume": 486388.97,
//         "product_code": "MS",
//         "total_totalizer": 257.9000000000233,
//         "total_sales": 257.89999932050705,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 399483.31,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 399483.31,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 179029.91,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 179029.91,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 1160966.12,
//         "start_totalizer_volume": 1159757.25,
//         "product_code": "MS",
//         "total_totalizer": 1208.8700000001118,
//         "total_sales": 1202.0699987113476,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 615362.38,
//         "start_totalizer_volume": 614781.06,
//         "product_code": "MS",
//         "total_totalizer": 581.3199999999488,
//         "total_sales": 581.3099995851517,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 2141872.3900000006,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 2468.069997102022,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 10,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 4041.6,
//         "start_product_volume": 3900.1,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 3900.1,
//         "total_sales": -141.5
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 4041.6,
//         "start_product_volume": 4832.8,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 4832.8,
//         "total_sales": 791.2000000000003
//       }
//     ],
//     "total_tank_sales": 6563,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 900468.44,
//         "start_totalizer_volume": 899572.25,
//         "product_code": "MS",
//         "total_totalizer": 896.1899999999441,
//         "total_sales": 896.1800001859665,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 487049.91,
//         "start_totalizer_volume": 486646.87,
//         "product_code": "MS",
//         "total_totalizer": 403.03999999997905,
//         "total_sales": 402.36999902129173,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 399813.66,
//         "start_totalizer_volume": 399483.31,
//         "product_code": "MS",
//         "total_totalizer": 330.3499999999767,
//         "total_sales": 330.36999928951263,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 179029.91,
//         "product_code": "MS",
//         "total_totalizer": -179029.91,
//         "total_sales": 151.89000153541565,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 1162620.87,
//         "start_totalizer_volume": 1160966.12,
//         "product_code": "MS",
//         "total_totalizer": 1654.75,
//         "total_sales": 1614.3199979066849,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 616165.56,
//         "start_totalizer_volume": 615362.38,
//         "product_code": "MS",
//         "total_totalizer": 803.1800000000512,
//         "total_sales": 803.220000743866,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": -524263.3100000005,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 4198.349998682737,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 11,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 5947.8,
//         "start_product_volume": 1409.8,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 1409.8,
//         "total_sales": -4538
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 5947.8,
//         "start_product_volume": 4041.6,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 4041.6,
//         "total_sales": -1906.2000000000003
//       }
//     ],
//     "total_tank_sales": -10165,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 902050.06,
//         "start_totalizer_volume": 900468.44,
//         "product_code": "MS",
//         "total_totalizer": 1581.6200000001118,
//         "total_sales": 1554.6600069403648,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 487978.97,
//         "start_totalizer_volume": 487049.91,
//         "product_code": "MS",
//         "total_totalizer": 929.0599999999977,
//         "total_sales": 885.8500031232834,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 399813.66,
//         "start_totalizer_volume": 399813.66,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 179181.8,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 179181.8,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 1163915.12,
//         "start_totalizer_volume": 1162620.87,
//         "product_code": "MS",
//         "total_totalizer": 1294.25,
//         "total_sales": 1236.870001077652,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 616722.5,
//         "start_totalizer_volume": 616165.56,
//         "product_code": "MS",
//         "total_totalizer": 556.9399999999441,
//         "total_sales": 556.9000000357628,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 554825.8600000006,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 4234.280011177063,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 12,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 3350.3,
//         "start_product_volume": 4586.099999999999,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 4586.099999999999,
//         "total_sales": 1235.7999999999993
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 3350.3,
//         "start_product_volume": 5947.8,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 5947.8,
//         "total_sales": 2597.5
//       }
//     ],
//     "total_tank_sales": 8046.799999999999,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 903471.62,
//         "start_totalizer_volume": 902050.06,
//         "product_code": "MS",
//         "total_totalizer": 1421.5599999999395,
//         "total_sales": 1426.9499951004982,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 488750.22,
//         "start_totalizer_volume": 487978.97,
//         "product_code": "MS",
//         "total_totalizer": 771.25,
//         "total_sales": 771.2599963042885,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 399813.66,
//         "product_code": "MS",
//         "total_totalizer": -399813.66,
//         "total_sales": 622.4999996423721,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 179181.8,
//         "product_code": "MS",
//         "total_totalizer": -179181.8,
//         "total_sales": 345.05999660491943,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 1164905.75,
//         "start_totalizer_volume": 1163915.12,
//         "product_code": "MS",
//         "total_totalizer": 990.6299999998882,
//         "total_sales": 990.6599988937378,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 617125.44,
//         "start_totalizer_volume": 616722.5,
//         "product_code": "MS",
//         "total_totalizer": 402.9399999999441,
//         "total_sales": 402.9799984693527,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": -2122030.2300000004,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 4559.409985015169,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 13,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 1625.6,
//         "start_product_volume": 3160.2,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 3160.2,
//         "total_sales": 1534.6
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 1625.6,
//         "start_product_volume": 3350.3,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 3350.3,
//         "total_sales": 1724.7000000000003
//       }
//     ],
//     "total_tank_sales": -1548.5999999999995,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 904563,
//         "start_totalizer_volume": 903471.62,
//         "product_code": "MS",
//         "total_totalizer": 1091.3800000000047,
//         "total_sales": 1104.8799988031387,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 489397.75,
//         "start_totalizer_volume": 488750.22,
//         "product_code": "MS",
//         "total_totalizer": 647.5300000000279,
//         "total_sales": 651.5899986028671,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 400432.12,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 400432.12,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 179526.84,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 179526.84,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 1166481.87,
//         "start_totalizer_volume": 1164905.75,
//         "product_code": "MS",
//         "total_totalizer": 1576.1200000001118,
//         "total_sales": 1576.089998692274,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 618065.5,
//         "start_totalizer_volume": 617125.44,
//         "product_code": "MS",
//         "total_totalizer": 940.0600000000559,
//         "total_sales": 940.0299981832504,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 2154187.2300000004,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 4272.58999428153,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 14,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 4442.5,
//         "start_product_volume": 5659.2,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 5659.2,
//         "total_sales": 1216.6999999999998
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 4442.5,
//         "start_product_volume": 1625.6,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 1625.6,
//         "total_sales": -2816.9
//       }
//     ],
//     "total_tank_sales": -479.60000000000036,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 905616.12,
//         "start_totalizer_volume": 904563,
//         "product_code": "MS",
//         "total_totalizer": 1053.1199999999953,
//         "total_sales": 1040.789996581152,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 489898.97,
//         "start_totalizer_volume": 489397.75,
//         "product_code": "MS",
//         "total_totalizer": 501.21999999997206,
//         "total_sales": 525.190005235374,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 400836.69,
//         "start_totalizer_volume": 400432.12,
//         "product_code": "MS",
//         "total_totalizer": 404.570000000007,
//         "total_sales": 404.559996843338,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 179526.84,
//         "product_code": "MS",
//         "total_totalizer": -179526.84,
//         "total_sales": 234.42000132799149,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 1168075.25,
//         "start_totalizer_volume": 1166481.87,
//         "product_code": "MS",
//         "total_totalizer": 1593.3799999998882,
//         "total_sales": 1597.399997740984,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 619004.06,
//         "start_totalizer_volume": 618065.5,
//         "product_code": "MS",
//         "total_totalizer": 938.5600000000559,
//         "total_sales": 925.1000007987022,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": -524012.1000000003,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 4727.459998527542,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 15,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 8131.4,
//         "start_product_volume": 3082.1,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 3082.1,
//         "total_sales": -5049.299999999999
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 8131.4,
//         "start_product_volume": 4442.5,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 4442.5,
//         "total_sales": -3688.8999999999996
//       }
//     ],
//     "total_tank_sales": -1213.599999999999,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 906434.94,
//         "start_totalizer_volume": 905616.12,
//         "product_code": "MS",
//         "total_totalizer": 818.8199999999488,
//         "total_sales": 818.800000667572,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 490382.34,
//         "start_totalizer_volume": 489898.97,
//         "product_code": "MS",
//         "total_totalizer": 483.37000000005355,
//         "total_sales": 483.3800008893013,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 400887.44,
//         "start_totalizer_volume": 400836.69,
//         "product_code": "MS",
//         "total_totalizer": 50.75,
//         "total_sales": 57.51000061631203,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 179761.27,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 179761.27,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 1168075.25,
//         "product_code": "MS",
//         "total_totalizer": -1168075.25,
//         "total_sales": 1496.4200012087822,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 619004.06,
//         "product_code": "MS",
//         "total_totalizer": -619004.06,
//         "total_sales": 667.0000033378601,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": -2408337.9800000004,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 3523.1100067198277,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 16,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 5681.2,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": -5681.2
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 5681.2,
//         "start_product_volume": 8131.4,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 8131.4,
//         "total_sales": 2450.2
//       }
//     ],
//     "total_tank_sales": -6690.000000000003,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 907464,
//         "start_totalizer_volume": 906434.94,
//         "product_code": "MS",
//         "total_totalizer": 1029.0600000000559,
//         "total_sales": 1029.0599969625473,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 490766.47,
//         "start_totalizer_volume": 490382.34,
//         "product_code": "MS",
//         "total_totalizer": 384.12999999994645,
//         "total_sales": 384.140000263229,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 401647.66,
//         "start_totalizer_volume": 400887.44,
//         "product_code": "MS",
//         "total_totalizer": 760.2199999999721,
//         "total_sales": 753.4600020051003,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 179938.16,
//         "start_totalizer_volume": 179761.27,
//         "product_code": "MS",
//         "total_totalizer": 176.89000000001397,
//         "total_sales": 170.1399992480874,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 1169571.62,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 1169571.62,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 619684.56,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 619684.56,
//         "total_sales": 0,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 2970494.3600000003,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 2336.799998478964,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 17,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 5795.200000000001,
//         "start_product_volume": 5795.200000000001,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 5795.200000000001,
//         "total_sales": 0
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 5681.2,
//         "start_product_volume": 5681.2,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 5681.2,
//         "total_sales": 0
//       }
//     ],
//     "total_tank_sales": 0,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 907464,
//         "start_totalizer_volume": 907464,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 1178.019991695881,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 490766.47,
//         "start_totalizer_volume": 490766.47,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 529.269999563694,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 401647.66,
//         "start_totalizer_volume": 401647.66,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 506.9700003862381,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 179938.16,
//         "start_totalizer_volume": 179938.16,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 155.0099995136261,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 1169571.62,
//         "start_totalizer_volume": 1169571.62,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 1189.940001964569,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 619684.56,
//         "start_totalizer_volume": 619684.56,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 755.7200026512146,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 0,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 4314.929995775223,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 18,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 3924.2,
//         "start_product_volume": 3924.2,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 3924.2,
//         "total_sales": 0
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 3327.2,
//         "start_product_volume": 3327.2,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 3327.2,
//         "total_sales": 0
//       }
//     ],
//     "total_tank_sales": 0,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 0,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 0,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 19,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       }
//     ],
//     "total_tank_sales": 0,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 0,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 0,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 20,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       }
//     ],
//     "total_tank_sales": 0,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 0,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 0,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 21,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       }
//     ],
//     "total_tank_sales": 0,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 0,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 0,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 22,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       }
//     ],
//     "total_tank_sales": 0,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 0,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 0,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 23,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       }
//     ],
//     "total_tank_sales": 0,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 0,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 0,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 24,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       }
//     ],
//     "total_tank_sales": 0,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 0,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 0,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 25,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       }
//     ],
//     "total_tank_sales": 0,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 0,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 0,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 26,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       }
//     ],
//     "total_tank_sales": 0,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 0,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 0,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 27,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       }
//     ],
//     "total_tank_sales": 0,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 0,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 0,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 28,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       }
//     ],
//     "total_tank_sales": 0,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 0,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 0,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 29,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       }
//     ],
//     "total_tank_sales": 0,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 0,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 0,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   },
//   {
//     "day": 30,
//     "year": 2023,
//     "month": 4,
//     "tanks": [
//       {
//         "tank": "3",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       },
//       {
//         "tank": "1",
//         "end_product_volume": 0,
//         "start_product_volume": 0,
//         "product_code": "MS",
//         "decant_volume": 0,
//         "total_Start": 0,
//         "total_sales": 0
//       }
//     ],
//     "total_tank_sales": 0,
//     "nozzles": [
//       {
//         "nozzle_id": "1_1_2",
//         "du_id": "1",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "1_2_1",
//         "du_id": "1",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_1_2",
//         "du_id": "2",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "2_2_1",
//         "du_id": "2",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_1_2",
//         "du_id": "3",
//         "pump_id": "1",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       },
//       {
//         "nozzle_id": "3_2_1",
//         "du_id": "3",
//         "pump_id": "2",
//         "next_start_totalizer_volume": 0,
//         "start_totalizer_volume": 0,
//         "product_code": "MS",
//         "total_totalizer": 0,
//         "total_sales": 0,
//         "test_sales": 0
//       }
//     ],
//     "cumulative_totalizer": 0,
//     "testing_totalizer": 0,
//     "profit_totalizer_d": 0,
//     "cumulative_sales": 0,
//     "profit_sales_f": 0,
//     "d_minus_f": 0,
//     "2t_oil": 0,
//     "gain_per_totalizer": 0,
//     "gain_per_transaction": 0,
//     "cumulative_gain_per_totalizer": 0,
//     "cumulative_gain_per_transaction": 0
//   }
// ]

constructor(private api:BaseRequestService, public cs: CommonService){

}


ngOnInit(): void {
  this.getproduct()
}

getproduct(){
  // product
  let q = {skip:0, limit:10000}
    this.api.doRequest(`/api/product_master`, 'get', null, q).subscribe((result: any) => {
      if(result && result.data && result.data.length>0){
        this.product=[];
        result.data.forEach(prod =>{
          if(prod.product_name == 'MOGAS'){
            prod.product_name = 'Petrol'
          }
          if(prod.product_name == 'GAS OIL'){
            prod.product_name = 'Diesel'
          }
          this.product.push ({
            id:prod._id,
            product_code:prod.product_code,
            product_name:prod.product_name
          })
          this.producthash[prod.product_code]= {
            id:prod._id,
            product_code:prod.product_code,
            product_name:prod.product_name
          }
        })
        this.cs.producthash = this.producthash
        this.product_code = this.product[0].product_code
      }
      this.fetchRecord()
    })
}

fetchRecord(){
  try{    
    var request =
      {
        "ro_code": this.rocode,
        "product": this.product_code,
        "month": this.date.value.format('yyyy-MM')
      }  
      
      localStorage.setItem("dsr_request", JSON.stringify(request))
    this.api.doRequest('/api/dutransaction/dsm_reports', 'post', request).subscribe(data =>{
      console.log("data response", data)
      if(data){
        this.data = data
        this.formdata()
      }
    }, error =>{
        console.log("error",  error)
    })
  }
  catch{
    console.log("error")
  }

}

formdata(){
  this.monthData = this.data[0]
  this.data.forEach( (day , j)=>{
    day.total_tank_sales = 0;
      day.tanks.forEach( (tan, i) =>{
        if(j < this.data.length-1){
        const nexttank = this.data[j+1].tanks.filter(x => x.tank == tan.tank)
        tan.end_product_volume = nexttank[0].start_product_volume}
        tan.total_sales = tan.total_Start - tan.end_product_volume
          day.total_tank_sales =  day.total_tank_sales + tan.total_sales
      })
      day.cumulative_totalizer = 0;
      day.cumulative_sales = 0;
      day.nozzles.forEach( noz =>{
        if(j < this.data.length-1){
        var nextnozzle = this.data[j+1].nozzles.filter(x => x.nozzle_id == noz.nozzle_id)
        if(nextnozzle[0].start_totalizer_volume === 0 && j < this.data.length-2){
           nextnozzle = this.data[j+2].nozzles.filter(x => x.nozzle_id == noz.nozzle_id)
           if(nextnozzle[0].start_totalizer_volume == 0 && j < this.data.length -3){
            nextnozzle = this.data[j+3].nozzles.filter(x => x.nozzle_id == noz.nozzle_id)
           }
        }
        if(noz.start_totalizer_volume == 0){
          noz.start_totalizer_volume = nextnozzle[0].start_totalizer_volume
        }
        noz.next_start_totalizer_volume = nextnozzle[0].start_totalizer_volume}
      })
    })
    console.log("before", this.data)
    this.data.forEach( (day , j)=>{
    day.nozzles.forEach( noz =>{
      noz.total_totalizer = noz.next_start_totalizer_volume - noz.start_totalizer_volume
      day.cumulative_totalizer += noz.total_totalizer
      day.testing_totalizer += noz.test_sales
      day.cumulative_sales += noz.total_sales
    })
      day.profit_totalizer_d = day.cumulative_totalizer - day.testing_totalizer 
      day.profit_sales_f = day.cumulative_sales - day.testing_totalizer
      day.d_minus_f = day.profit_totalizer_d - day.profit_sales_f
      day.gain_per_totalizer = day.profit_totalizer_d - day.total_tank_sales
      day.gain_per_transaction = day.profit_sales_f - day.total_tank_sales
      day.cumulative_gain_per_totalizer = day.cumulative_totalizer - day.total_tank_sales
      day.cumulative_gain_per_transaction = day.cumulative_sales - day.total_tank_sales
  })

    console.log("after", this.data)


  
}


chosenYearHandler(normalizedYear: Moment) {
  const ctrlValue = this.date.value;
  ctrlValue.year(normalizedYear.year());
  this.date.setValue(ctrlValue);
}

chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
  const ctrlValue = this.date.value;
  ctrlValue.month(normalizedMonth.month());
  this.date.setValue(ctrlValue);
  datepicker.close();
}


}
