
    



    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto w-full sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <!-- Your other content here -->
            <ng-container >
                <ng-container *ngIf="du_transactions.length > 0; else noProducts">
                   
                        <ng-container>
                            <div class="grid md:grid-cols-5 md:grid-rows-2 sm:grid-cols-2 sm:gap-x-3 sm:gap-y-3 overflow-scroll gap-y-1 md:gap-y-1 md:gap-x-3">
                            <ng-container *ngFor="let trans of du_transactions;">
                               
                                <div (click)="routetoro(trans)" class="text-slate-600 cursor-pointer bg-white mx-auto grid max-w-2xl grid-rows-3 gap-y-4 sm:my-10 sm:rounded-xl sm:border sm:shadow">
                                    <div class="row-span-2  sm:col-span-1 sm:mx-0 sm:rounded-l-xl">
                                      <div class="flex items-center max-w-xs">
                                        <div class="flex w-full border border-yellow-300 rounded-lg items-center justify-center shadow-lg">
                                          <img class="rounded-lg" [src]="trans.blob_url">
                                        </div>
                                        
                                      </div>
                                      
                                    </div>
                                    <div class="row-span-1 grid grid-cols-1 sm:col-span-1">
                                        <!-- <div class="col-span-2 grid grid-cols-2 gap-4 py-2 sm:col-span-1 sm:gap-8 sm:px-4">
                                            <div class="col-span-2 -mb-8">
                                              <p class="text-blue-700 text-md font-medium">{{trans.fcc_txn_id}}</p>
                                            </div>
                                            <div class="">
                                              <span class="text-md font-light text-black">₹ {{trans.transaction_amount}}</span>
                                            </div>
                                          <div class="">
                                              <span class="text-md font-light text-black">{{trans.transaction_volume}} L</span>
                                          </div>
                                        </div> -->
                                        <div>
                                            <p class="text-secondary ml-2 text-md font-medium">#{{trans.fcc_txn_id}}</p>
                                            <span class="ml-2 text-primary-700 font-bold text-md">{{trans.vehicle_number}}</span>
                                        </div>
                                        <div class="flex flex-row justify-between pl-3 pr-3">
                                            <span class="text-md font-semibold text-black">₹ {{(trans.end_totalizer_amount - trans.start_totalizer_amount) | number: '1.0-2' }}</span>
                                            <span class="text-md font-semibold text-black">{{(trans.end_totalizer_volume - trans.start_totalizer_volume) | number: '1.0-2' }} L</span>
                                        </div>
                                       <!-- <div class="flex flex-col justify-center items-end pr-2 pb-1">
                                        <span class="text-md right-0 font-bold text-primary-800">{{processdate(trans.transaction_end_datetime)}}</span>

                                       </div> -->
                                       <div class="flex flex-row justify-between pl-3 pr-3">
                                        <span class="text-md font-semibold text-primary-800">{{processdateonly(trans.transaction_end_datetime)}}</span>
                                        <span class="text-md font-semibold text-primary-800">{{processdatetime(trans.transaction_end_datetime)}}</span>
                                    </div>
                                    </div>
                                  </div>


                            </ng-container>
                            </div>
                        </ng-container>
            <!-- Paginator -->
            <mat-paginator
                class="mat-mdc-paginator fixed bottom-0 flex flex-col flex-auto w-full overflow-hidden border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-card"
                [ngClass]="{'pointer-events-none': isLoading}"
                [length]="pagination.length"
                [pageIndex]="pagination.page"
                [pageSize]="pagination.size"
                (page)="getPageDetails($event)"
                [pageSizeOptions]="[10, 25, 50, 100]"
                [showFirstLastButtons]="true">
            </mat-paginator>
            </ng-container>
            <ng-template #noProducts>
                <div *ngIf="!loadingapi" class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">There are no transactions!</div>
            </ng-template>
            </ng-container>
        </div>
    </div>
    


